import "./index.css";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import React, { useEffect } from "react";

function TransSharePage() {
  document.title = "Transaction info";
  const openAppOrAppstore = () => {
    const schemeUrl = "lightsocial://detailpage";
    const fallbackUrl =
      "https://apps.apple.com/us/app/lightsocial/id6476731345";

    window.location.href = schemeUrl;

    setTimeout(() => {
      // 检测页面是否失去焦点
      if (document.visibilityState === "visible") {
        window.location.href = fallbackUrl;
      }
    }, 2000);
  };
  useEffect(() => {
    openAppOrAppstore();
  }, []);

  return (
    <div className="App">
      <header className="App-header"></header>
    </div>
  );
}

export default TransSharePage;
