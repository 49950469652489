import "./index.css";
import { usePrivy } from "@privy-io/react-auth";
import React, { useEffect } from "react";

function LinkTiktok() {
  document.title = "Link Tiktok";
  const { ready, authenticated, linkTiktok, user } = usePrivy();
  useEffect(() => {
    if (ready && authenticated && user) {
      if (user.tiktok == null) {
        linkTiktok();
      } else {
        window.location.href =
          "lightsocial://link_tiktok?tiktok_page=https://www.tiktok.com/@" +
          user.tiktok.username;
        fetch("https://sync-user-info-b552m5r5lq-uc.a.run.app/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage
              .getItem("privy:token")
              .replaceAll('"', ""),
          },
        });
      }
    }
  }, [ready, authenticated, user]);

  return (
    <div className="App">
      <header className="App-header">
        <button
          onClick={linkTiktok}
          style={{
            padding: "12px",
            backgroundColor: "#181818",
            color: "#FFF",
            width: "300px",
            alignItems: "center",
            borderWidth: "1px",
            border: "#44F641",
            borderRadius: "6px",
          }}
        >
          Link Tiktok
        </button>
      </header>
    </div>
  );
}

export default LinkTiktok;
