import "./index.css";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import React, { useEffect } from "react";
import Bridge from "./lib/JsBridge";
import {
  createPublicClient,
  createWalletClient,
  http,
  EstimateGasExecutionError,
  formatEther,
  parseEther,
  publicActions,
  custom,
} from "viem";
import abi from "./lib/abi.json";
import * as Constants from "./lib/Constants";
import bridge_abi from "./lib/bridge_abi.json";
import { baseSepolia } from "viem/chains";
import { privateKeyToAccount } from "viem/accounts";

function BlankPage() {
  let { ready, authenticated, getAccessToken } = usePrivy();
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy",
  );
  let inited = false;
  useEffect(() => {
    const queryString = window.location.search;
    // 解析参数字符串为对象
    const params = new URLSearchParams(queryString);
    let isSuperUser = false;
    let account = null;
    let privy_address = null;
    if (params && params.has("super")) {
      isSuperUser = params.get("super") === "1";
      if (isSuperUser) {
        ready = true;
        authenticated = true;
        account = privateKeyToAccount(Constants.APPLE_TEST_PRIVY_KEY);
        privy_address = Constants.APPLE_TEST_PRIVY_ADDRESS;
      }
    } else {
      if (ready && authenticated && embeddedWallet != null) {
        account = embeddedWallet.address;
        privy_address = embeddedWallet.address;
      }
    }
    if (ready && authenticated && account != null && !inited) {
      inited = true;
      console.log("begin init bridge");
      Bridge.registerHandler("getNewToken", async (data, responseCallback) => {
        if (!authenticated) {
          responseCallback(
            JSON.stringify({
              errno: -1,
              errmsg: "auth failed, please refresh the page",
            }),
          );
          return;
        }
        const token = await getAccessToken();
        const refreshToken = localStorage
          .getItem("privy:refresh_token")
          .replaceAll('"', "");
        responseCallback(
          JSON.stringify({
            token: token,
            refresh_token: refreshToken,
          }),
        );
      });
      // const data =
      //   '{"address":"0x249fC5C4397eb52C7AE6970F59Ef7C3744aBe2E1","amount":1,"currency":"BRL","chain_id":84532}';
      // const responseCallback = console.log;
      Bridge.registerHandler("getBuyPrice", async (data, responseCallback) => {
        if (!authenticated) {
          responseCallback(
            JSON.stringify({
              errno: -1,
              errmsg: "auth failed, please refresh the page",
            }),
          );
          return;
        }

        if (account == null) {
          responseCallback(
            JSON.stringify({
              errno: -2,
              errmsg: "can not get privy embedded wallet",
            }),
          );
          return;
        }
        try {
          const jsonData = JSON.parse(data);
          const currency = jsonData["currency"].toString();
          const useChain = Constants.getChain(
            jsonData.hasOwnProperty("chain_id")
              ? jsonData["chain_id"]
              : baseSepolia.id,
          );
          const provider = http(Constants.get_base_provider_url(useChain.id));
          const publicClient = createPublicClient({
            account: account,
            chain: useChain,
            transport: provider,
          });
          const price = await publicClient.readContract({
            address: Constants.getContractAddress(useChain.id),
            abi: abi,
            functionName: "getBuyPriceAfterFee",
            args: [jsonData["address"], jsonData["amount"]],
          });
          console.log("get price success, %s ETH", formatEther(price));
          let gas = 0;
          try {
            gas = await publicClient.estimateContractGas({
              address: Constants.getContractAddress(useChain.id),
              abi: abi,
              functionName: "buySeat",
              value: price,
              args: [jsonData["address"], jsonData["amount"]],
              account: account,
            });
          } catch (e) {}
          const rates = await Constants.getExchangeRate();
          const resultData = {
            priceETH: parseFloat(formatEther(price)).toFixed(6),
            gas: gas.toString(),
            gasFee:
              "$" + (parseFloat(formatEther(gas)) * rates[currency]).toFixed(4),
          };
          const priceMoney = parseFloat(formatEther(price)) * rates[currency];
          if (!isNaN(priceMoney)) {
            resultData["price"] = currency + " $" + priceMoney.toFixed(4);
          }
          responseCallback(
            JSON.stringify({
              errno: 0,
              errmsg: "success",
              data: resultData,
            }),
          );
        } catch (e) {
          console.log(e.stack);
          responseCallback(
            JSON.stringify({
              errno: -3,
              errmsg: e.message,
            }),
          );
        }
      });

      // const data =
      //   '{"address":"0x249fC5C4397eb52C7AE6970F59Ef7C2744aBe2E0","amount":1,"currency":"BRL","chain_id":84532}';
      Bridge.registerHandler("getSellPrice", async (data, responseCallback) => {
        if (!authenticated) {
          responseCallback(
            JSON.stringify({
              errno: -1,
              errmsg: "auth failed, please refresh the page",
            }),
          );
          return;
        }

        if (account == null) {
          responseCallback(
            JSON.stringify({
              errno: -2,
              errmsg: "can not get privy embedded wallet",
            }),
          );
          return;
        }
        try {
          const jsonData = JSON.parse(data);
          const currency = jsonData["currency"].toString();
          const useChain = Constants.getChain(
            jsonData.hasOwnProperty("chain_id")
              ? jsonData["chain_id"]
              : baseSepolia.id,
          );
          const provider = http(Constants.get_base_provider_url(useChain.id));
          const publicClient = createPublicClient({
            account: account,
            chain: useChain,
            transport: provider,
          });
          const price = await publicClient.readContract({
            address: Constants.getContractAddress(useChain.id),
            abi: abi,
            functionName: "getSellPriceAfterFee",
            args: [jsonData["address"], jsonData["amount"]],
          });
          const gas = await publicClient.estimateContractGas({
            address: Constants.getContractAddress(useChain.id),
            abi: abi,
            functionName: "sellSeat",
            args: [jsonData["address"], jsonData["amount"]],
            account: account,
          });
          const rates = await Constants.getExchangeRate();
          const resultData = {
            priceETH: parseFloat(formatEther(price)).toFixed(6),
            gas: gas.toString(),
            gasFee:
              "$" + (parseFloat(formatEther(gas)) * rates[currency]).toFixed(4),
          };
          const priceMoney = parseFloat(formatEther(price)) * rates[currency];
          if (!isNaN(priceMoney)) {
            resultData["price"] = currency + " $" + priceMoney.toFixed(4);
          }
          responseCallback(
            JSON.stringify({
              errno: 0,
              errmsg: "success",
              data: resultData,
            }),
          );
        } catch (e) {
          console.log(e.stack);
          responseCallback(
            JSON.stringify({
              errno: -3,
              errmsg: e.message,
            }),
          );
        }
      });

      // const data =
      //   '{"address":"0xa20442124a3EBB4Cc81468Fa7eD9e4C4643fd4E6","amount":0.001,"currency":"BRL","chain_id":84532}';
      // const responseCallback = console.log;
      Bridge.registerHandler(
        "getEstimatePrice",
        async (data, responseCallback) => {
          if (!authenticated) {
            responseCallback(
              JSON.stringify({
                errno: -1,
                errmsg: "auth failed, please refresh the page",
              }),
            );
            return;
          }

          if (account == null) {
            responseCallback(
              JSON.stringify({
                errno: -2,
                errmsg: "can not get privy embedded wallet",
              }),
            );
            return;
          }
          try {
            const jsonData = JSON.parse(data);
            const currency = jsonData["currency"].toString();
            const useChain = Constants.getChain(
              jsonData.hasOwnProperty("chain_id")
                ? jsonData["chain_id"]
                : baseSepolia.id,
            );
            const provider = http(Constants.get_base_provider_url(useChain.id));
            const publicClient = createPublicClient({
              account: account,
              chain: useChain,
              transport: provider,
            });
            const balancePromise = publicClient.getBalance({
              address: privy_address,
            });
            const changeRatePromise = await Constants.getExchangeRate();
            const gasFeePromise = await publicClient.estimateContractGas({
              address: Constants.getBridgeContractAddress(useChain.id),
              abi: bridge_abi,
              value: parseEther(jsonData["amount"].toString()),
              functionName: "initiateWithdrawal",
              args: [jsonData["address"], 100000, "0x01"],
              account: account,
            });
            Promise.all([balancePromise, changeRatePromise, gasFeePromise])
              .then((response) => {
                const balance = response[0];
                const rates = response[1];
                const gasFee = response[2];
                const data = {
                  amount: jsonData["amount"],
                  gas: gasFee.toString(),
                  gasFee:
                    "$" +
                    (parseFloat(formatEther(gasFee)) * rates[currency]).toFixed(
                      4,
                    ),
                  balance: parseFloat(formatEther(balance)).toFixed(6),
                };
                const amountOfMoney =
                  parseFloat(jsonData["amount"]) * rates[currency];
                if (!isNaN(amountOfMoney)) {
                  data["amountOfMoney"] =
                    currency + " $" + amountOfMoney.toFixed(4);
                }
                const inputAmountInWei = parseEther(
                  jsonData["amount"].toString(),
                );
                const leftInWei = inputAmountInWei - gasFee;
                data["leftInEther"] = parseFloat(
                  formatEther(leftInWei),
                ).toFixed(6);
                const leftMoney =
                  parseFloat(formatEther(leftInWei)) * rates[currency];
                if (!isNaN(leftMoney)) {
                  data["leftMoney"] = currency + " $" + leftMoney.toFixed(4);
                }
                responseCallback(
                  JSON.stringify({
                    errno: 0,
                    errmsg: "success",
                    data: data,
                  }),
                );
              })
              .catch((error) => {
                // 处理错误
                console.log(error.stack);
                if (error instanceof EstimateGasExecutionError) {
                  responseCallback(
                    JSON.stringify({
                      errno: -3,
                      errmsg: error.shortMessage,
                    }),
                  );
                }
              });
          } catch (e) {
            console.log(e.stack);
            responseCallback(
              JSON.stringify({
                errno: -3,
                errmsg: e.shortMessage,
              }),
            );
          }
        },
      );

      // const data = '{"currency":"BRL","chain_id":84532}';
      Bridge.registerHandler(
        "getWalletInfo",
        async (data, responseCallback) => {
          if (account == null) {
            responseCallback(
              JSON.stringify({
                errno: -2,
                errmsg: "can not get privy embedded wallet",
              }),
            );
            return;
          }

          try {
            const jsonData = JSON.parse(data);
            const currency = jsonData["currency"].toString();
            const useChain = Constants.getChain(
              jsonData.hasOwnProperty("chain_id")
                ? jsonData["chain_id"]
                : baseSepolia.id,
            );
            const provider = http(Constants.get_base_provider_url(useChain.id));
            const publicClient = createPublicClient({
              account: account,
              chain: useChain,
              transport: provider,
            });
            const balance = await publicClient.getBalance({
              address: privy_address,
            });
            const rates = await Constants.getExchangeRate();

            const resultData = {
              chain_name: useChain.name,
              network: useChain.network,
              address: privy_address,
              msg: "The price of the key is equal to N^2 / 54321 ether where N is the current number of keys. 5% of the transaction will go to host.",
              balanceETH: parseFloat(formatEther(balance)).toFixed(6),
            };
            const balanceMoney =
              parseFloat(formatEther(balance)) * rates[currency];
            if (!isNaN(balanceMoney)) {
              resultData["balance"] = currency + " $" + balanceMoney.toFixed(4);
            }
            responseCallback(
              JSON.stringify({
                errno: 0,
                errmsg: "success",
                data: resultData,
              }),
            );
          } catch (e) {
            console.log(e.stack);
            responseCallback(
              JSON.stringify({
                errno: -3,
                errmsg: e.message,
              }),
            );
          }
        },
      );

      // const data =
      //   '{"address":"0xa20442124a3EBB4Cc81468Fa7eD9e4C4643fd4E6","amount":1,"op":"sell","chain_id":84532}';
      // const responseCallback = console.log;
      Bridge.registerHandler(
        "seatTransaction",
        async (data, responseCallback) => {
          if (!authenticated) {
            responseCallback(
              JSON.stringify({
                errno: -1,
                errmsg: "auth failed, please refresh the page",
              }),
            );
            return;
          }

          if (account == null) {
            responseCallback(
              JSON.stringify({
                errno: -2,
                errmsg: "can not get privy embedded wallet",
              }),
            );
            return;
          }

          try {
            const jsonData = JSON.parse(data);
            const toAddress = jsonData["address"];
            const amount = jsonData["amount"];
            const op = jsonData["op"];
            const useChain = Constants.getChain(
              jsonData.hasOwnProperty("chain_id")
                ? jsonData["chain_id"]
                : baseSepolia.id,
            );
            let provider = http(Constants.get_base_provider_url(useChain.id));
            if (!isSuperUser) {
              await embeddedWallet.switchChain(useChain.id);
              provider = custom(await embeddedWallet.getEthereumProvider());
            }
            const walletClient = createWalletClient({
              account: account,
              chain: useChain,
              transport: provider,
            }).extend(publicActions);
            const priceFunctionName =
              op === "sell" ? "getSellPriceAfterFee" : "getBuyPriceAfterFee";
            const price = await walletClient.readContract({
              address: Constants.getContractAddress(useChain.id),
              abi: abi,
              functionName: priceFunctionName,
              args: [toAddress, amount],
            });
            console.log("get price success, %s", price);
            const functionName = op === "sell" ? "sellSeat" : "buySeat";
            var tx_params = {
              account: account,
              address: Constants.getContractAddress(useChain.id),
              abi: abi,
              functionName: functionName,
              args: [toAddress, amount],
            };
            if (functionName === "buySeat") {
              tx_params["value"] = price;
            }
            tx_params["gas"] =
              await walletClient.estimateContractGas(tx_params);
            const hash = await walletClient.writeContract(tx_params);
            console.log("write contract success, %s", hash);
            responseCallback(
              JSON.stringify({
                errno: 0,
                errmsg: "success",
              }),
            );
            const postData = {
              hash: hash,
              address: toAddress,
              amount: amount,
              price: parseFloat(formatEther(price)).toFixed(6),
              op: op === "sell" ? "sell" : "buy",
            };

            if (isSuperUser) {
              Bridge.callHandler("getToken", "", (res) => {
                if (res !== "") {
                  const tokenData = JSON.parse(res);
                  fetch("https://record-seat-b552m5r5lq-uc.a.run.app/", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: tokenData["token"],
                    },
                    body: JSON.stringify(postData), // 将数据转换为JSON字符串并作为请求体发送
                  }).then((r) => {
                    console.log("record transaction success");
                  });
                }
              });
            } else {
              fetch("https://record-seat-b552m5r5lq-uc.a.run.app/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: localStorage
                    .getItem("privy:token")
                    .replaceAll('"', ""),
                },
                body: JSON.stringify(postData), // 将数据转换为JSON字符串并作为请求体发送
              }).then((r) => {
                console.log("record transaction success");
              });
            }
          } catch (e) {
            console.log(e.stack);
            responseCallback(
              JSON.stringify({
                errno: -3,
                errmsg: e.message,
              }),
            );
          }
        },
      );

      // const data =
      //   '{"address":"0xa20442124a3EBB4Cc81468Fa7eD9e4C4643fd4E6","amount":0.001,"op":"sell","chain_id":84532}';
      // const responseCallback = console.log;
      Bridge.registerHandler("withdraw", async (data, responseCallback) => {
        if (!authenticated) {
          responseCallback(
            JSON.stringify({
              errno: -1,
              errmsg: "auth failed, please refresh the page",
            }),
          );
          return;
        }

        if (account == null) {
          responseCallback(
            JSON.stringify({
              errno: -2,
              errmsg: "can not get privy embedded wallet",
            }),
          );
          return;
        }
        try {
          const jsonData = JSON.parse(data);
          const toAddress = jsonData["address"];
          const amount = jsonData["amount"];
          const useChain = Constants.getChain(
            jsonData.hasOwnProperty("chain_id")
              ? jsonData["chain_id"]
              : baseSepolia.id,
          );
          let provider = http(Constants.get_base_provider_url(useChain.id));
          if (!isSuperUser) {
            await embeddedWallet.switchChain(useChain.id);
            provider = custom(await embeddedWallet.getEthereumProvider());
          }
          const walletClient = createWalletClient({
            account: account,
            chain: useChain,
            transport: provider,
          }).extend(publicActions);
          const gas = await walletClient.estimateContractGas({
            address: Constants.getBridgeContractAddress(useChain.id),
            abi: bridge_abi,
            value: parseEther(amount.toString()),
            functionName: "initiateWithdrawal",
            args: [toAddress, 100000, "0x01"],
            account: account,
          });
          const hash = await walletClient.writeContract({
            account: account,
            address: Constants.getBridgeContractAddress(useChain.id),
            abi: bridge_abi,
            gas: gas,
            value: parseEther(amount.toString()),
            functionName: "initiateWithdrawal",
            args: [toAddress, gas, "0x01"],
          });
          responseCallback(
            JSON.stringify({
              errno: 0,
              errmsg: "success",
            }),
          );
          console.log("withdraw success, hash=" + hash);
        } catch (e) {
          console.log(e.stack);
          responseCallback(
            JSON.stringify({
              errno: -3,
              errmsg: e.message,
            }),
          );
        }
      });

      Bridge.callHandler("bridgeInited", "", () => {});
    }
  }, [ready, embeddedWallet, authenticated]);

  return <></>;
}

export default BlankPage;
