import { baseSepolia, base } from "viem/chains";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
export const SEPOLIA_CONTRACT_ADDRESS =
  "0x481C322386617D75F8C5bcDC1c1FFe41dAb6D89C";
export const BASE_CONTRACT_ADDRESS =
  "0x36610D16AB1874A5bd77e40e3830892FDB60d9e0";

export const SEPOLIA_BRIDGE_CONTRACT_ADDRESS =
  "0x4200000000000000000000000000000000000016";
export const BASE_BRIDGE_CONTRACT_ADDRESS =
  "0x4200000000000000000000000000000000000016";

export const APPLE_TEST_PRIVY_ADDRESS =
  "0xf141Cf6F4962989289b0a2Bf78900DA75CF1A06A";

export const APPLE_TEST_PRIVY_KEY =
  "0x15a1e1deabf6b34a4c72806d03d24791353f6760a77e38b44c78887897d0f18b";

export function getContractAddress(useChainId) {
  if (useChainId + "" === base.id + "") {
    return BASE_CONTRACT_ADDRESS;
  }

  return SEPOLIA_CONTRACT_ADDRESS;
}

export function getChain(useChainId) {
  if (base.id + "" === useChainId + "") {
    return base;
  }

  return baseSepolia;
}

export function getBridgeContractAddress(useChainId) {
  if (useChainId + "" === base.id + "") {
    return BASE_BRIDGE_CONTRACT_ADDRESS;
  }

  return SEPOLIA_BRIDGE_CONTRACT_ADDRESS;
}

export function get_base_provider_url(use_chain_id) {
  if (use_chain_id === base.id) {
    return "https://base-mainnet.blastapi.io/a5849679-242e-4f7b-a063-51c61e394751";
  }

  return "https://base-sepolia.blastapi.io/a5849679-242e-4f7b-a063-51c61e394751";
}

export const getExchangeRate = async () => {
  var requestOptions = { method: "GET", redirect: "follow" };
  return fetch(
    "https://api.coinbase.com/v2/exchange-rates?currency=ETH",
    requestOptions,
  )
    .then((response) => response.json())
    .then((result) => {
      return result.data.rates;
    })
    .catch((error) => {
      return "error", error;
    });
};

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCKp5H660mtp5S-TJhvcp9IQxOr4XX-Zg4",
  authDomain: "chainews-d3cbd.firebaseapp.com",
  projectId: "chainews-d3cbd",
  storageBucket: "chainews-d3cbd.appspot.com",
  messagingSenderId: "230937232610",
  appId: "1:230937232610:web:c526d7689c2a0ea732cabb",
  measurementId: "G-KRBRW75NH4",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
