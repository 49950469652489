import "./index.css";
import { getAccessToken, usePrivy, useWallets } from "@privy-io/react-auth";
import React, { useEffect, useState } from "react";
import { SpinLoading } from "antd-mobile";

function Login() {
  document.title = "Login";
  const { ready, authenticated, login, user, createWallet, exportWallet } =
    usePrivy();
  const { wallets } = useWallets();
  wallets.find((wallet) => wallet.walletClientType === "privy");
  const [loading, setLoading] = useState(false);
  // async function findSignInButton() {
  //   return new Promise((resolve) => {
  //     let appleButton = null;
  //     const allButtons = document.querySelectorAll("button");
  //     allButtons.forEach((button) => {
  //       if (button.textContent.trim() === "Apple") {
  //         appleButton = button;
  //       }
  //     });
  //     if (appleButton != null) {
  //       resolve(appleButton);
  //     } else {
  //       setTimeout(function () {
  //         findSignInButton().then((button) => {
  //           if (button != null) {
  //             resolve(button);
  //           }
  //         });
  //       }, 50);
  //     }
  //   });
  // }

  function loginClick() {
    login();
  }

  useEffect(() => {
    if (ready) {
      if (!authenticated) {
        loginClick();
      } else {
        if (loading) return;
        setLoading(true);
        createWallet()
          .then((value) => {
            console.log(value);
          })
          .finally(() => {
            getAccessToken().then((token) => {
              fetch("https://sync-user-info-b552m5r5lq-uc.a.run.app/", {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: localStorage
                    .getItem("privy:token")
                    .replaceAll('"', ""),
                },
              })
                .then(() => {
                  setLoading(false);
                  const refreshToken = localStorage
                    .getItem("privy:refresh_token")
                    .replaceAll('"', "");
                  window.location.href =
                    "lightsocial://login_callback?info=" +
                    JSON.stringify(user) +
                    "&token=" +
                    token +
                    "&refresh_token=" +
                    refreshToken;
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          });
      }
    }
  }, [ready, authenticated]);

  if (!ready) {
    return null;
  }

  return (
    <div className="App">
      <header className="App-header">
        {ready && authenticated ? (
          <div>
            {loading ? (
              <SpinLoading color="default" />
            ) : (
              <div>
                <button
                  onClick={exportWallet}
                  style={{
                    padding: "12px",
                    backgroundColor: "#181818",
                    color: "#FFF",
                    width: "300px",
                    borderWidth: "1px",
                    border: "#44F641",
                    borderRadius: "6px",
                  }}
                >
                  Export my wallet
                </button>
              </div>
            )}
          </div>
        ) : (
          <button
            onClick={loginClick}
            style={{
              padding: "12px",
              backgroundColor: "#181818",
              color: "#FFF",
              width: "300px",
              borderWidth: "1px",
              border: "#44F641",
              borderRadius: "6px",
            }}
          >
            Log In
          </button>
        )}
      </header>
    </div>
  );
}

export default Login;
