import "./index.css";
import { usePrivy } from "@privy-io/react-auth";
import React, { useEffect } from "react";

function Logout() {
  document.title = "Logout";
  const { ready, authenticated, logout } = usePrivy();

  useEffect(() => {
    if (ready) {
      if (authenticated) {
        logout().then((r) => {
          console.log("logout success");
          window.location.href = "lightsocial://logout_callback";
        });
      } else {
        console.log("logout success");
        window.location.href = "lightsocial://logout_callback";
      }
    }
  }, [ready]);

  if (!ready) {
    return null;
  }

  return (
    <div className="App">
      <header className="App-header">
        {ready && authenticated ? (
          <button
            onClick={logout}
            style={{
              padding: "12px",
              backgroundColor: "#181818",
              color: "#FFF",
              width: "300px",
              borderWidth: "1px",
              border: "#44F641",
              borderRadius: "6px",
            }}
          >
            Log Out
          </button>
        ) : (
          <div></div>
        )}
      </header>
    </div>
  );
}

export default Logout;
