import "./index.css";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import React, { useEffect } from "react";

function LinkWallet() {
  document.title = "Link Wallet";
  const { ready, authenticated, linkWallet } = usePrivy();
  const { wallets } = useWallets();
  useEffect(() => {
    if (ready && authenticated && wallets.length > 0) {
      link_wallet();
    }
  }, [ready, authenticated, wallets]);

  function link_wallet() {
    const externalWallet = wallets.find((wallet) => {
      return wallet.walletClientType !== "privy" && wallet.linked === true;
    });
    if (externalWallet == null) {
      linkWallet();
    } else {
      window.location.href = "lightsocial://link_wallet";
      fetch("https://sync-user-info-b552m5r5lq-uc.a.run.app/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage
            .getItem("privy:token")
            .replaceAll('"', ""),
        },
      });
    }
  }
  return (
    <div className="App">
      <header className="App-header">
        <button
          onClick={link_wallet}
          style={{
            padding: "12px",
            backgroundColor: "#181818",
            color: "#FFF",
            width: "300px",
            alignItems: "center",
            borderWidth: "1px",
            border: "#44F641",
            borderRadius: "6px",
          }}
        >
          Link Wallet
        </button>
      </header>
    </div>
  );
}

export default LinkWallet;
