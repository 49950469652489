import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { PrivyProvider } from "@privy-io/react-auth";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Logout from "./Logout";
import BlankPage from "./BlankPage";
import LinkWallet from "./LinkWallet";
import LoginProvider from "./LoginProvider";
import LinkTiktok from "./LinkTiktok";
import ExportWallet from "./Export";
import TransSharePage from "./TransShare";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LoginProvider>
      <PrivyProvider
        appId={process.env.REACT_APP_PRIVY_APP_ID}
        config={{
          embeddedWallets: {
            noPromptOnSignature: true,
            createOnLogin: "all-users",
          },
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/blank" element={<BlankPage />} />
            <Route path="/link_wallet" element={<LinkWallet />} />
            <Route path="/link_tiktok" element={<LinkTiktok />} />
            <Route path="/export" element={<ExportWallet />} />
            <Route path="/trans_info" element={<TransSharePage />} />
          </Routes>
        </BrowserRouter>
      </PrivyProvider>
    </LoginProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
