import "./index.css";
import { usePrivy } from "@privy-io/react-auth";
import React, { useEffect } from "react";

function ExportWallet() {
  document.title = "Export";
  const { ready, authenticated, login, exportWallet } = usePrivy();

  useEffect(() => {
    if (ready && authenticated) {
      exportWallet();
    }
  }, [ready, authenticated]);

  return (
    <div className="App">
      <header className="App-header">
        {ready && authenticated ? (
          <div>
            <button
              onClick={exportWallet}
              style={{
                padding: "12px",
                backgroundColor: "#181818",
                color: "#FFF",
                width: "300px",
                borderWidth: "1px",
                border: "#44F641",
                borderRadius: "6px",
              }}
            >
              Export my wallet
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </header>
    </div>
  );
}

export default ExportWallet;
