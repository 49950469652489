import "./index.css";
import React, { useEffect } from "react";
import Bridge from "./lib/JsBridge";
import { usePrivy } from "@privy-io/react-auth";

function setTokenIfNeeded(token, refresh_token) {
  let currentToken = localStorage.getItem("privy:token");
  if (currentToken != null) {
    currentToken = currentToken.replaceAll('"', "");
  }
  if (currentToken !== token) {
    localStorage.setItem("privy:token", '"' + token + '"');
    localStorage.setItem("privy:refresh_token", '"' + refresh_token + '"');
    document.cookie = "privy-token=" + token;
    document.cookie = "privy-refresh-token=" + refresh_token;
    window.location.reload();
  }
}

function LoginProvider({ children }) {
  const { ready, logout } = usePrivy();
  const queryString = window.location.search;
  // 解析参数字符串为对象
  const params = new URLSearchParams(queryString);
  if (params && params.has("token") && params.has("refresh_token")) {
    setTokenIfNeeded(params.get("token"), params.get("refresh_token"));
  } else {
    Bridge.callHandler("getToken", "", (res) => {
      if (res !== "") {
        const tokenData = JSON.parse(res);
        setTokenIfNeeded(tokenData["token"], tokenData["refresh_token"]);
      }
    });
  }

  useEffect(() => {
    if (ready) {
      Bridge.registerHandler("logout", (data, responseCallback) => {
        logout()
          .then(() => {
            console.log("logout success");
          })
          .catch((reason) => {
            console.log("logout failed, reason:" + reason);
          });
      });
    }
  }, [ready]);

  return <div>{children}</div>;
}

export default LoginProvider;
